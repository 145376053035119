"use client";

import NavContainer from "@/components/common/navContainer";
import Loading from "@/components/common/loading";
import Login from "@/components/common/login";
import { useAuthContext } from "./AuthContextProvider";
import chameleon from "@chamaeleonidae/chmln";
import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { SubscriptionStatus } from "@/enums/stripe";

let ChameleonInitialized = false;

export const NavContainerProvider = ({
  children,
  isAdmin,
  setShowUserManagement,
}: {
  children: React.ReactNode;
  isAdmin?: boolean;
  setShowUserManagement: (value: boolean) => void;
}) => {
  const router = useRouter();
  const auth = useAuthContext();

  const [initializedUserId, setInitializedUserId] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (!auth.user || initializedUserId === auth.user.id) {
      return;
    }

    setInitializedUserId(auth.user.id);

    const userNeedsOrgSelection = auth.user && !auth.user.orgId;
    const userNeedsOnboarding = auth.user && !auth.user.agencyId;
    if (userNeedsOrgSelection || userNeedsOnboarding) {
      router.push("/springboard");
      return;
    }

    if (!ChameleonInitialized && process.env.NEXT_PUBLIC_CHAMELEON_LICENSE) {
      ChameleonInitialized = true;

      chameleon.init(process.env.NEXT_PUBLIC_CHAMELEON_LICENSE, {
        fastUrl: "https://fast.chameleon.io/",
      });
    }

    if (!isAdmin && auth.user) {
      const companyProperties = auth.user.agencyId
        ? {
            company: {
              agencyId: auth.user.agencyId,
              plan: auth.isFreeTrial
                ? "FreeTrial"
                : auth.subscription?.status === SubscriptionStatus.none
                ? "FreeTrial"
                : auth.subscription?.status,
            },
          }
        : {
            company: {
              plan: "FreeTrial",
            },
          };

      chameleon.identify(auth.user.id, {
        email: auth.user.email,
        name: auth.user.name,
        companyProperties,
      });
    }
  }, [auth.user]);

  if (auth.isLoading) {
    return <Loading />;
  }

  const isOnboarded = auth.user && auth.user.agencyId && auth.user.orgId;
  if (auth.user != undefined && !isOnboarded) {
    return <Loading />;
  }

  return (
    <NavContainer setShowUserManagement={setShowUserManagement}>
      {auth.user != undefined ? <>{children}</> : <Login />}
    </NavContainer>
  );
};

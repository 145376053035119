"use client";

import { Icon, ICON_SIZES } from "@lula-technologies-inc/lux";
import clsx, { ClassValue } from "clsx";
import Link from "next/link";

type ButtonProps = {
  children?: React.ReactNode;
  label?: React.ReactNode;
  type?: "primary" | "secondary" | "tertiary";
  compact?: boolean;
  wide?: boolean;
  disabled?: boolean;
  id?: string;
  href?: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  iconBefore?: React.ReactNode;
  iconAfter?: React.ReactNode;
  testId?: string;
  className?: ClassValue;
};

const Button: React.FC<ButtonProps> = ({
  href,
  onClick,
  type = "primary",
  className,
  id,
  testId: dataTestId,
  wide,
  compact,
  disabled,
  children,
  iconBefore,
  iconAfter,
  target = "_self",
}) => {
  if (!href && !onClick) {
    throw new Error("You must have an href or onClick prop");
  }

  if (href && target === "_blank" && !iconAfter) {
    iconAfter = (
      <Icon
        icon="arrow-up-right-from-square"
        size={compact ? ICON_SIZES.XXS : ICON_SIZES.SM}
      />
    );
  }

  const commonProps = {
    id,
    disabled,
    "data-testid": dataTestId,
    className: clsx("-skew-x-12", className, {
      ["py-2 px-4"]: !compact,
      ["py-1 px-2"]: compact,
      ["cursor-not-allowed"]: disabled,
      // Primary
      ["bg-button_primary border-2 border-button_primary hover:bg-button_primary/75"]:
        !disabled && type == "primary",
      ["bg-button_primary/50 border-2 border-button_primary/50 grayscale-75"]:
        disabled && type === "primary",
      // Secondary
      ["m-[2px] bg-button_secondary hover:bg-button_secondary/75"]:
        !disabled && type == "secondary",
      ["m-[2px] bg-button_secondary/50 grayscale-75"]:
        disabled && type == "secondary",
      // Teritary
      ["m-[2px] bg-button_tertiary/25 border-2 border-button_tertiary hover:bg-button_tertiary/50"]:
        !disabled && type == "tertiary",
      ["m-[2px] bg-white border-2 border-button_tertiary"]:
        disabled && type == "tertiary",
      ["w-full"]: wide,
    }),
  };

  const buttonChildren = (
    <div
      className={clsx("skew-x-12", "text-center", {
        ["text-button_text_primary text-shadow shadow-gray-900/75"]:
          type == "primary",
        ["text-button_text_secondary text-shadow-sm shadow-white-900/75"]:
          !disabled && type == "secondary",
        ["text-button_text_secondary/50 text-shadow-sm shadow-white-900/75"]:
          disabled && type == "secondary",
        ["text-sm"]: compact && type !== "tertiary",
        ["text-gray-400"]: disabled && type == "tertiary",
        ["text-button_text_tertiary text-sm"]: !compact && type == "tertiary",
        ["text-button_text_tertiary text-xs"]: compact && type === "tertiary",
      })}
    >
      {iconBefore && (
        <span className={clsx("mr-2", "text-current")}>{iconBefore}</span>
      )}
      {children}
      {iconAfter && <span className="ml-2">{iconAfter}</span>}
    </div>
  );

  if (onClick) {
    return (
      <button onClick={onClick} {...commonProps}>
        {buttonChildren}
      </button>
    );
  }

  if (href) {
    return (
      <Link href={href} target={target} {...commonProps}>
        {buttonChildren}
      </Link>
    );
  }
};

export default Button;
